
@import 'helpers/_parameters';

$ui-toggle-padding: 8px !default;
$ui-radio-size: 20px !default;
// Top-level host container.
.ui-radio-button {
  display: inline-block;
  -webkit-tap-highlight-color: transparent;
  &.ui-small {
    .ui-radio-container {
      height: 32px;
      width: auto;
      padding: 16px;
      border:1px solid #BDBDBD;
    }
    &.ui-radio-checked {
      .ui-radio-container {
        height: 32px;
        border:1px solid black;
      }
    }
  }
}

[ui-tile] {
  text-transform: uppercase;
}

// Inner label container, wrapping entire element.
// Enables focus by click.
.ui-radio-label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  vertical-align: middle;
  margin-bottom: 0;
}

// Container for radio circles and ripple.
.ui-radio-container {

  font-family: mark;
  font-weight: bold;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  width: 180px;
  height: 48px;
  // The radio container is inside of a inline-flex element and may shrink if the label
  // is wide and text-wrapping is enabled.
  flex-shrink: 0;
  background-color: white;
  border:2px solid black;
}
.ui-radio-checked .ui-radio-container {
  background-color: black;
  color:white;
  border: none;
}
input.ui-radio-input:disabled ~ .ui-radio-container {
  border: none;
  background-color: lightgrey;
  color: grey;
}


// Text label.
.ui-radio-label-content {
  display: inline-block;
  order: 0;
  line-height: inherit;
}

// Basic disabled state.
.ui-radio-disabled, .ui-radio-disabled .ui-radio-label {
  cursor: default;
}


