@import 'ui-kit/styles/palette';

.uikit-input {
  font: inherit;
  background: transparent;
  color: currentColor;
  outline: none;

  width: 100%;
  border: none;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;
  padding: 0 0.75rem;


  &::placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: $color-grey3;
  }

  &.error {
    border-color: $color-bad-default;
  }

  &.host-selected:not(:disabled) {
    background: $color-black;
    color: white;
  }
}

textarea.uikit-input {
  resize: none;
  padding: 0.5rem;
  box-sizing: content-box;
}
