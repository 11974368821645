@import 'ui-kit/styles/palette';

/* Customize the label (the container) */
.checkbox-container {
  display: inline-flex;
  position: relative;
  margin-bottom: 0;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;


  cursor: pointer;
  font-weight: normal;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: content-box;

  &.checked,
  &.indeterminate {
    .checkmark {
      background-color: black;
    }
  }

  &.disabled.checked,
  &.disabled.indeterminate {
    .checkmark {
      background-color: $color-grey4;
    }
  }

  &.disabled {
    color: $color-grey3;
    cursor: default;

    .checkmark {
      border: 2px solid $color-grey4;
    }
  }

  /* Hide the browser's default checkbox */
  input {
    display: none;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Show the checkmark when checked */
  /* Style the checked checkmark/indicator */
  input:checked~.checkmark:after {
    display: block;
    left: 3px;
    top: -1px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(40deg);
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
  }

  /* Style the indeterminate checkmark/indicator */
  input:indeterminate~.checkmark:after {
    display: block;
    left: 1px;
    top: 4px;
    width: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: relative;
    height: 16px;
    width: 16px;
    border: 2px solid black;
    margin: 2px;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

}
