@import 'ui-kit/styles/palette';

.required {
  color: $color-pink;
}

.errors {
  line-height: 32px;
  color: $color-bad-default;
}

.ui-dropdown.invalid {
  .dropdown-options {
    border: 2px solid $color-bad-default;
    color: $color-bad-default;
  }
}

.ui-dropdown.no-border {
  .dropdown-options {
    border: none;
  }

  .dropdown-label {
    label {
      margin: 0px;
    }
  }
}

.chevron {
  position: relative;
  transition: transform 0.2s;
  top: 0;
  left: -8px;
  height: 18px;
}

.expanded .chevron {
  transform: rotate(180deg);
}

.ui-dropdown {
  display: inline-block;
  width: 100%;

  .dropdown-label {
    label {
      margin: 5px 0;
      font-size: 0.875rem;
    }
  }

  .dropdown-options {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 42px;

    &.disabled {
      opacity: 0.5;
      user-select: none;
    }

    background: #fff;
    border: 1px solid black;
    position: relative;
    overflow: hidden;

    &.error {
      border: 2px solid $color-bad-default;
    }

    .selected-value {
      height: 40px;
      width: 100%;
      padding: 0 16px;
      line-height: 40px;
    }

    &:hover:not(.disabled) {
      background: $color-grey6;
    }
  }
}

.dropdown-list-container {
  color: #000;

  &.hidden {
    height: 0;
    border: 0;
    overflow: hidden;
  }

  margin: 0;
  padding: 0;
  border: 1px solid black;
  background-color: white;
  width: 100%;
  overflow: hidden;

  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.16);

  .scroll-viewport {
    /* multiples of 54px */
    height: 216px;
  }

  ul {
    margin-bottom: 0;
  }

  li {
    padding-right: 16px;

    .item-content {
      cursor: pointer;
      padding: 16px 0 16px 16px;
      line-height: 1.375rem;
    }

    span.selected {
      font-weight: bold;
    }

    &:hover {
      color: white;
      background-color: black;
    }

    &.multi-select {
      position: relative;
      padding: 16px 16px 16px 40px;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .item-content {
        padding: 0px;
        line-height: 1rem;
      }

      input:checked ~ .checkmark {
        background-color: black;
        border-color: black;
      }

      .checkmark {
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
        border: 2px solid black;
        margin: 12px;
      }

      &:hover input:checked ~ .checkmark {
        background-color: white;
        border-color: white;

        &:after {
          border-color: black;
        }
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: '';
        position: absolute;
        display: none;
      }

      input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the checkmark/indicator */
      .checkmark::after {
        left: 1.5px;
        top: -2px;
        width: 9px;
        height: 14px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(40deg);
        -webkit-transform: rotate(40deg);
        -ms-transform: rotate(40deg);
      }
    }
  }
}

.justify-center {
  justify-content: center;
}

ul.minimal {
  border: none;

  li {
    font-size: 0.75rem;
    line-height: 1;
  }
}

.ui-dropdown.minimal {
  .dropdown-label {
    display: none;
  }

  .dropdown-options {
    height: 1.75rem;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;

    &:focus {
      outline: none;
    }

    .selected-value {
      height: auto;
      width: auto;
      font-size: 0.75rem;
      padding: 0 2px;
      line-height: 1.75rem;
    }

    &:hover:not(.disabled) {
      background: transparent;
    }
  }

  .chevron {
    top: 0;
    left: 0;
  }
}

.ui-dropdown.survey {
  .dropdown-options {
    min-width: 272px;
    max-width: 327px;
    border-radius: 24px;
    padding: 12px 24px;
    border-width: 2px;
    border-color: #fff;
    outline: none;

    background: transparent;
    color: #fff;

    &:hover,
    &.option-selected {
      background: $color-pink;
    }

    &:active {
      outline: none;
    }

    .selected-value {
      color: #fff;
      height: auto;
      padding: 0;
      font-size: 1rem;
      line-height: 20px;
      font-weight: bold;
    }
  }

  &.expanded {
    .dropdown-options {
      background: transparent !important;
      border-color: transparent;
    }
  }
}

.dropdown-list-container.survey {
  border-color: transparent;

  li {
    color: #000;

    &.selected {
      color: #fff;
      font-weight: normal;
      background-color: $color-pink;
    }
  }
}

.host-selected {
  .dropdown-options {
    background: $color-black;
    color: $color-white;

    &:hover {
      background: $color-black !important;
    }
  }
}

.ng-scrollbar.dropdown-scrollbar {
  --scrollbar-thumb-color: rgba(127, 127, 127, 0.4);
}
