@import 'helpers/parameters';
@import 'ui-kit/styles/palette';

.ui-card {
  display: block;
  position: relative;
  margin-bottom: 24px;
}

@mixin card-title {
  font-family: mark;
  font-size: 1.375rem;
  font-weight: bold;
  line-height: 1.75rem;
  color: $color-black;

  @media #{$breakpoint-web} {
    font-size: 1.75rem;
    line-height: 2.25rem;
  }
}

@mixin card-subtitle {
  display: inline-block;
  margin-bottom: 8px;
  color: $color-grey2;
  text-transform: uppercase;
  font-family: mark;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 1px;
}

ui-card-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media #{$breakpoint-handset} {
  ui-card-column {
    display: block;
    min-width: unset;
  }
}

.questions-container {
  overflow: hidden;
}

.arrow-expand {
  font-size: 10px;
  margin-right: 8px;
}

.card-container {
  flex: 1;
  max-width: 1112px;
  background-color: $color-white;
  box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.03);
  opacity: 1;

  .ui-card.full-width & {
    max-width: none;
  }

  .ui-card.with-header-width & {
    max-width: 1087px;
  }

  :host(.transparent) & {
    margin: 0 !important;
  }

  .ui-card-title {
    @include card-title;
  }

  .ui-card-subtitle {
    @include card-subtitle;
  }
}

.no-bottom-margin {
  margin-bottom: 0px;
}

ui-card.ui-table {
  .card-container-header {
    padding: 24px 32px;
  }
}

ui-card.fixed-height {
  .card-container {
    height: 100%;
  }

  .ui-card-tab-content {
    height: 100%;
    overflow: hidden;
  }
}

.card-container-header {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  :host(.action-card) & {
    flex-direction: row !important;
    justify-content: space-between !important;
  }

  .button-label {
    font-size: 1.25rem !important;
  }
}

.search-filter-row {
  padding: 40px 48px;
}

.ui-card-title,
.ui-card-subtitle {
  display: block;
}

.ui-card-content {
  display: block;
  padding: 0 24px;

  &.no-padding {
    padding: 0;
  }

  &.no-side-margin {
    margin: 24px 0;
  }

  &.center {
    flex-direction: column;
    align-items: center;
  }

  .mat-nav-list,
  .mat-table,
  .mat-selection-list {
    margin: 0 -24px;
  }

  .mat-list {
    margin: 0 -16px;
  }

  .mat-progress-bar {
    width: 100vw;
    margin: 0 -48px;
  }
}

.no-padding {
  .ui-card-content {
    padding: 0;
  }
}

.ui-card-tab-content {
  height: 100%;
  padding: 24px;

  .mat-nav-list,
  .mat-table,
  .mat-list,
  .mat-selection-list {
    margin: unset;
  }
}

ui-card.ui-table {
  .ui-card-content {
    padding: 0;
  }

  .ui-card-content .mat-nav-list,
  .ui-card-content .mat-table,
  .ui-card-content .mat-list,
  .ui-card-content .mat-selection-list {
    margin: 0;
  }
}

.ui-card-footer {
  min-height: 48px;
  padding: 0 48px 32px 48px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;

  a {
    display: flex;
    align-self: flex-end;
    justify-self: flex-end;
    font-weight: bold;
    color: $color-black;

    &::after {
      display: inline-block;
      border: 4px solid $color-black;
      content: '';
      height: 7px;
      width: 36px;
      margin-left: 16px;
      align-self: center;
    }
  }
}

.ui-summary-card {
  .card-container {
    background-color: $color-petrol-lightest;
  }
}

/* Colum card */
ui-card.column {
  width: 100%;

  .card-container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 32px 24px 32px;

    &:not(:empty) {
      padding: 24px 24px 8px 24px;
    }
  }

  .ui-card-footer {
    min-height: auto;
    padding: 24px 32px 24px 32px;
  }

  .card-container {
    display: flex;
    flex-direction: column;

    .column-wrapper {
      display: flex;
    }
  }

  & .icon-column {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-pink;
  }

  .column-icon {
    height: 200px;
    width: auto;
  }

  uikit-icon.column-icon {
    height: 124px;
    width: 124px;
  }

  &.icon-card ui-card-title {
    display: block;
    font-size: 82px;
    line-height: 82px;
    font-weight: bold;
    padding-top: 32px;
  }

  .ui-card-content {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    font-size: 16px;
    line-height: 26px;
  }

  ui-card-column:not(:first-of-type).tight .ui-card-content {
    padding-left: 16px;
  }

  ui-card-column:first-of-type.tight .ui-card-content {
    padding-right: 16px;
  }
}

@media #{$breakpoint-tablet-portrait} {

  /* This is not perfect, but atleast prevents cards from overflowing horizontally when somewhere around 600-700px screen width */
  ui-card.column {
    &.mobile-reverse {
      .card-container {
        .column-wrapper {
          flex-flow: column-reverse;
        }
      }
    }

    .card-container {
      .column-wrapper {
        flex-direction: column;
      }
    }
  }
}

/* Mobile  general*/

@media #{$breakpoint-handset} {

  .column_1,
  .column_2,
  .column_3,
  .column_4,
  .column_5 {
    flex: none;
  }

  .ui-card-content {
    padding: 0 24px;
  }

  ui-card.column {
    min-height: 255px;
    flex: none;

    & .icon-column {
      min-height: 255px;
    }

    &.icon-card ui-card-title {
      font-size: 58px;
    }

    &.mobile-reverse {
      .card-container {
        .column-wrapper {
          flex-flow: column-reverse;
        }
      }
    }

    .card-container {
      margin: 0;

      .column-wrapper {
        flex-direction: column;
      }
    }

    .ui-card-content {
      padding: 0 24px;
      font-size: 1rem;
      line-height: 1.5rem;

      p {
        margin-bottom: 0px;
      }
    }

    .card-container-header {
      &:not(:empty) {
        padding: 24px 24px 16px 24px;
      }
    }

    .ui-card-footer {
      padding: 24px 48px 16px 48px;
    }
  }
}

.bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
}

.ui-card {
  &.action-card {
    .card-container-header {
      flex-direction: row !important;
      justify-content: space-between !important;
      align-items: center;
    }
  }

  &.transparent {
    .card-container {
      background: transparent;
      box-shadow: none;
    }

    .card-container-header {
      padding: 32px 12px 24px 0px;
      margin: 0 12px;
    }

    .ui-card-content {
      padding: 0;
    }
  }

  @media #{$breakpoint-web} {
    &.transparent {
      .card-container {
        margin: 0;
      }
    }
  }
}

@media #{$breakpoint-handset, $breakpoint-tablet} {
  .ui-card .transparent {
    .card-container-header {
      margin: 0 4px;
    }
  }
}

ui-card-collapsable-v1 .card-container-header {
  padding: 24px 48px 12px 48px;
}

// THEMES
.theme-petrol-variant {
  .card-container {
    background-color: $color-petrol-lightest;
    color: $color-black;
  }
}

.theme-pink {

  .card-container,
  .show-hide-questions-container,
  .card-container-header {
    background-color: $color-pink;
    color: $color-white;

    & .ui-card-subtitle {
      background-color: $color-pink;
      color: $color-white;
    }
  }

  .hide-questions-container {
    background-color: $color-white;
    color: $color-black;
  }

  .no-top-margin {
    border-color: $color-grey4;
  }
}

.theme-pink-header {

  .show-hide-questions-container,
  .card-container-header {
    background-color: $color-pink;
    color: $color-white;

    & .ui-card-subtitle {
      background-color: $color-pink;
      color: $color-white;
    }
  }
}

.theme-white-blue {
  ui-card-column:nth-child(even) {
    background-color: $color-blue;
    color: white;

    .ui-card-title {
      color: white;
    }
  }
}
.theme-white-petrol-lightest {
  ui-card-column:nth-child(even) {
    background-color: $color-petrol-lightest;
    color: black;

    .ui-card-title {
      color: black;
    }
  }
}

@mixin print-display-color {
  @media print {
    print-color-adjust: exact;
  }
}

@mixin print-break-avoid {
  @media print {
    display: block;
    break-inside: avoid !important;
  }
}
