@use 'ui-kit/styles/palette';
@use 'helpers/_parameters';

uikit-form-field {
  display: inline-flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;

  &.uikit-form-field-type-uikit-input {
    width: 100%;
    padding: 1px;
  }

  &:has(uikit-select) {
    /* use this for select for now, until we can forward controlType to form-field */
    max-width: 368px;
  }

  &:not(.uikit-form-field-disabled):hover .uikit-infix-wrapper {
    background-color: palette.$color-grey7;
  }
}

.uikit-form-field-wrapper {
  position: relative;

  &:has(.uikit-subscript-wrapper) {
    padding-bottom: 1.5rem;
  }
}

.uikit-flex-wrapper {
  width: 100%;
  display: flex;
  padding: 1px;
  flex-direction: column;
  align-items: flex-start;
}

.label-padding {
  padding-top: 1.5rem;
}

uikit-form-field.compact .uikit-flex-wrapper {
  padding-bottom: 0;

  &.label-padding {
    padding-top: 1rem;
  }
}

.uikit-infix-wrapper {
  display: flex;
  position: relative;
  flex: auto;
  align-items: center;
  min-width: 100%;
  max-width: 800px;
  width: 100%;
  background-color: #fff;

  transition: background-color 0.15s;
  overflow: visible;

  border: 1px solid #000;
  border-radius: parameters.$select-border-radius;
  justify-content: space-between;
  align-items: center;

  &:has(input:focus-within),
  &:has(textarea:focus-within) {
    outline: 1px solid #000;

    &:has(.ng-invalid) {
      outline-color: palette.$color-bad-default;
    }

  }

  &:has(.ng-invalid) {
    border-color: palette.$color-bad-default;
  }

  .fancy & {
    border: none;
  }

  input {
    width: 100%;
    outline: none;
    border: unset;
    padding: 0 12px;
    display: inline-block;
    background-color: transparent;
    line-height: 40px;
    transition: background-color 0.2s;
    border-radius: 4px;
  }

  :host-context(uikit-select-search) {
    .uikit-infix-wrapper {
      input {
        &:focus {
          outline: none;
        }
      }
    }

  }

  textarea {
    width: 100%;
    max-width: 600px;
  }


}


.uikit-form-field-label {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: palette.$color-black;
}

.uikit-subscript-wrapper {
  position: absolute;
  font-size: 0.875rem;
  right: 8px;
}

.uikit-suffix {
  position: absolute;
  right: 2px;
  font-size: 1.375rem;
  padding-right: 4px;
  padding-left: 4px;
}


input:disabled,
textarea:disabled {
  border-color: palette.$color-grey3;
  color: palette.$color-grey3;
}
